import React from 'react';
import TitleImageDesc from '../ListItems/TitleImageDesc/TitleImageDesc';
import TitleVideoDesc from '../ListItems/TitleVideoDesc/TitleVideoDesc';
import classes from './Section.module.css';

const Section = (props) =>
{
    const targetStyle = props.fadeToggle ?
        [classes.Section, classes.ContentFadeIn].join(' ') :
        [classes.Section, classes.ContentFadeIn2].join(' ');
    
    function getItemByData(itemData, itemIndex) {
        //console.log(itemData.videoUrl);

        /// item type: TitleVideoDesc
        const item = (itemData.videoUrl !== undefined) ? 
            <TitleVideoDesc
                title={itemData.title}
                videoUrl={itemData.videoUrl}
                fallbackImgUrl={itemData.fallbackImgUrl}
                imgAlt=""
                bodyText={itemData.bodyText}
                key={itemIndex}
            /> :
            /// item type: TitleImageDesc
            (itemData.imgUrl !== undefined) ?
                <TitleImageDesc 
                    title={itemData.title}
                    imgUrl={itemData.imgUrl}
                    bodyText={itemData.bodyText}
                    key={itemIndex} /> :
                /// item type: 'unrecognized'
                <p className={classes.ItemTypeError}>Item type not recognized! Make sure your props are named correctly!</p>;
        
        return item;
    }
    
    return(
        <div className={targetStyle}>
            {props.curSection.items.map((item, itemIndex) => {
                return getItemByData (item, itemIndex);
            })}
        </div>
    );
}

export default Section;