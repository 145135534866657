import React from 'react';
import classes from './TitleVideoDesc.module.css';
import ReactPlayer from 'react-player';


const listItem = props => {
    // add this to not trigger eslint no-undef
    /* global Modernizr */

    // compute if video supported, add video player or image thereafter.
    //console.log(Modernizr);
    //console.log("supports video tag?=",Modernizr.video);
    //console.log("props.fallbackImgUrl=",props.fallbackImgUrl)
    const videoSupported = Modernizr.video;;
    const videoContent = 
        // is html5video supported in this browser?
        videoSupported ? 
        // yes; show video player
        <ReactPlayer 
            className={classes.ReactPlayer} 
            url={props.videoUrl}
            fallback={<img src={props.fallbackImgUrl} alt=""/>}
            width={"100%"}
            height={"100%"}
            controls
        /> :
        // no; show image
        <img src={props.fallbackImgUrl} alt=""/>;
    
    return (
        <div className={classes.ListItem}>
            <h1>{props.title}</h1>
            {videoContent}
            {/*<p>{props.bodyText}</p>*/}
            <p dangerouslySetInnerHTML={{__html:props.bodyText}}/>
        </div>
    );
}

export default listItem;