import React from 'react';
import classes from './Pilot.module.css';

const pilot = props => {
    const targetStyle = props.fadeToggle ?
        [classes.Pilot, classes.ContentFadeIn].join(' ') :
        [classes.Pilot, classes.ContentFadeIn2].join(' ');
    return (
        <div className={targetStyle}>
            <h1>{props.title}</h1>
            <p dangerouslySetInnerHTML={{__html:props.bodyText}}/>
        </div>
    );
}

export default pilot;