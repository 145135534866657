import React from 'react';
import classes from './TitleImageDesc.module.css';

const listItem = props => {
    return (
        <div className={classes.ListItem}>
            <h1>{props.title}</h1>
            <img src={props.imgUrl} alt="" />
            {/*<p>{props.bodyText}</p>*/}
            <p dangerouslySetInnerHTML={{__html:props.bodyText}}/>
        </div>
    );
}

export default listItem;