// README: https://www.npmjs.com/package/react-app-polyfill
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

//import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


/*
const render = ({ hash }) => {
  return <div>{`hash: ${hash}`}</div>;
};
ReactDOM.render(
  <HashChange
      onChange={({ hash }) => console.log({ hash })}
      render={render}
  />,
  document.getElementById('root')
);*/


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
