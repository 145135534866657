import React from 'react';
import classes from './Header.module.css';
/*import {BrowserRouter, Route, Link} from 'react-router-dom';*/
import { useHistory } from "react-router-dom";
import {getValidSectionIndexByRoute} from '../../utils/Utils';
//import {HashChange} from "react-hashchange";

/*
const triggered = () => {
    console.log("getLocationHash");
    //return window.location.hash;
};
*/

const Header = props => {
    const history = useHistory();
    //console.log(getLocationHash);
    //console.log("history=",history); // wrong hash; also, 'pathname' sometimes undefined..
    const hashString = window.location.hash.slice(1); // same as history.pathname
    let curSectionIndex = getValidSectionIndexByRoute(props.state.sections, hashString);
    //console.log('Header:refresh; curSectionIndex='+curSectionIndex);
    
    /*function getValidSectionIndexByRoute(sections, route)
    {
        //console.log(props.sections);
        let itemIndex = sections.findIndex(item => {
            //console.log(item.route);
            return (item.route === route);
        });

        // -1 for ex. if user typed 'mysite.com/' into address bar, 
        // but the site.json does not define that route; in that case, 
        // we should assume 'first in list' section/route is selected
        //console.log(itemIndex); 
        if (itemIndex === -1) {
            itemIndex = 0;
        }

        return itemIndex;
    }*/

    function runPrevRoute() {
        console.log('runPrevRoute; curSectionIndex='+curSectionIndex+"; sections.length="+props.state.sections.length);
        if (--curSectionIndex < 0) {
            curSectionIndex = props.state.sections.length - 1;
        }
        console.log('history.push: '+ props.state.sections[curSectionIndex].route);
        history.push (props.state.sections[curSectionIndex].route);
        console.log('runPrevRoute; new route=',props.state.sections[curSectionIndex].route);
        
        props.onRouteChanged(curSectionIndex);
    }

    function runNextRoute () {
        if (++curSectionIndex >= props.state.sections.length) {
            curSectionIndex = 0;
        }
        history.push (props.state.sections[curSectionIndex].route);
        
        props.onRouteChanged(curSectionIndex);
    }

    return (
        <div className={classes.Header}>
            <div className={classes.HeaderContents}>
                <button id={classes["arrowLeft"]} onClick={runPrevRoute}>
                    <img src={props.imgArrowLeft} alt=""/>
                </button>
                <img id={classes["imgLeft"]} src={props.imgUrlLeft} alt="" />
                <div id={classes["title"]}>
                    <h1>{props.title}</h1>
                </div>
                <button id={classes["arrowRight"]} onClick={runNextRoute}>
                    <img src={props.imgArrowRight} alt="" />
                </button>
                <img id={classes["imgRight"]} src={props.imgUrlRight} alt="" />
            </div>
        </div>
    );
}


export default Header;