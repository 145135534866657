//import 'core-js/es/map';
//import 'core-js/es/set';

import React, {Component} from 'react';
import classes from './App.module.css';
import Header from '../components/Header/Header';
import Pilot from '../components/Pilot/Pilot';
import Section from '../components/Section/Section';
import axios from 'axios';
import {HashRouter} from 'react-router-dom';
import {getValidSectionByRoute} from '../utils/Utils';
import {getValidSectionIndexByRoute} from '../utils/Utils';
import HashChange from "react-hashchange";


class App extends Component {
  state = {
    // display 'loading' text while loading site (json) data (asynch)
    isLoading: true
    , isError: false
    , error: null
    // this flag is only necessary to have for 'react-hashchange' resolution 
    // (hash changed manually by user)
    , sectionIndex: 0
    , route: ""
    , fadeToggle: false

    // header {imgArrowRight, imgUrlLeft, imgUrlRight}
    , header: {forceRefresh:false}

    // pilot {title, bodyText}
    , pilot: {}

    // sections[] > listitems[] > listitem {title, imgUrl, desc}
    , sections: []
  };

  componentDidMount() {
    console.log("App::componentDidMount");
    // 
    // console.log: 
    // {
        // addressbar shows: http://localhost:3000/#/one/two/
        // window.location:
        // hash: "#/one/two/"
        // host: "localhost:3000"
        // hostname: "localhost"
        // href: "http://localhost:3000/#/one/two/"
        // origin: "http://localhost:3000"
        // pathname: "/"
        // port: "3000"
        // protocol: "http:"
    // }
    //const pathname = window.location.pathname;
    //console.log(getValidSectionIndexByRoute(this.state.sections, pathname));
    //console.log(window.location);

    // if we get error loading data
    // .catch() and finally() runs
    // else .then() and .finally() runs
    // if there is error during parsing (parseData)
    // .then() and catch() and finally() all run
    axios.get('./data/json/site.json')
    .then(response => {
      //console.log("then");
      //console.log("parsing data..");
      this.parseData(response.data);
      //console.log("data parsed");
    })
    .catch(err => {
      //console.log("catch: ", err);
      this.setState({
        isError: true
        , error: err
      })
    })
    // guaranteed to be run even if error
    /*.finally(() => {
      console.log("finally");
      this.setState({
        isLoading: false
      })
    });*/
  }

  /// parses data returned by axios (JSON model)
  /// into state data 
  parseData = (data) => {
    this.setState({
      isLoading: false
      , header: data.header
      , pilot: data.pilot
      , sections: data.sections
    });
  }

  /*
  // set new href/route
  // calculate section index and set that index
  prevSection = () => {
    //console.log("prevSection");
  }

  nextSection = () => {
    //console.log("nextSection");
  }
  */

  /// route change via Header->button click
  onRouteChanged = (newSectionIndex) => {
    // Force a render without state change...
    //this.forceUpdate();

    //console.log("App::onRouteChanged;");
    //console.log("setting secIndex to "+newSectionIndex+" and refreshing..");
    // the hash in addressbar has changed. 
    // lets change cur section index to force re-render
    this.setState({
      /// this flag is only necessary to have for 'react-hashchange' resolution (hash changed manually by user)
      sectionIndex: newSectionIndex
      , fadeToggle: !this.state.fadeToggle
    });
  }

  /// hash has changed
  /// this can trigger from 2 places:
  /// > clicking prev/next button by user
  ///   > dont handle this usecase (hashes are same)
  /// > user manually types a hash in addressbar and hits enter
  ///   > this usecase is handled here; we need to re-render dom
  ///     hint: state->sectionIndex is changed by nav buttons
  ///           so if the current addressbar url and sections[sectionIndex]->route 
  ///           are same, do NOT rerender the dom; else, do re-render the dom;
  onHashChanged = (hash) => {
      //console.log("App::onHashChanged");
      
      const slicedHash = (typeof hash === "string") ? hash.slice(1) : hash.hash.slice(1);
      //console.log(1, this.state.sections[this.state.sectionIndex].route);
      //console.log(2, slicedHash);
      
      if (this.state.sections[this.state.sectionIndex].route !== slicedHash) {
        //this.forceUpdate();
        // set proper section index, which in turn causes re-render
        const newSectionIndex = getValidSectionIndexByRoute(this.state.sections, slicedHash);
        this.setState({
          /// this flag is only necessary to have for 'react-hashchange' resolution (hash changed manually by user)
          sectionIndex: newSectionIndex
          , fadeToggle: !this.state.fadeToggle
        });
      }
  };

  render() {
    /*
    window.location.href returns the href (URL) of the current page
    window.location.hostname returns the domain name of the web host
    window.location.pathname returns the path and filename of the current page
    window.location.protocol returns the web protocol used (http: or https:)
    window.location.assign() loads a new document
    */
    //console.log("render: ", window.location.hostname);
    //console.log("render: ", window.location.pathname);

    if (this.state.isLoading) {
      return (
        <div className={classes.App}>
          <p>Loading, please wait..</p>
        </div>
      );
    }
    else
    if (this.state.isError) {
      return (
        <div className={classes.App}>
          <p>Following error has occured while loading site data:</p>
          <p>{this.state.error}</p>
        </div>
      );
    }

    // else process and display site content
    // > create JSX representation of list items for this section
    // sections []
    //  section {}
    //    items []
    //      item {}
    //      item {}
    //  section {}
    //    items[]
    //      item {}
    //      .. 
    //  section ..
    //  ..
    //const curSection = this.state.sections[this.state.sectionIndex];
    //const pathname = window.location.pathname;
    //const curSection = getValidSectionByRoute(this.state.sections, pathname);
    //console.log("App::render; state.sectionIndex="+this.state.sectionIndex);
    //console.log("App::render; hash="+window.location.hash);

    // slice the hash char from the url hash. ex: #/sec1 -> /sec1
    const hashString = window.location.hash.slice(1);
    const curSection = getValidSectionByRoute(this.state.sections, hashString);
    //const curSectionIndex = getValidSectionIndexByRoute(this.state.sections, hashString);
    //console.log("App::render; curSectionIndex="+curSectionIndex);
    
    // execute function reanimate section fadeIn?


    return (
      <HashRouter>

        {/* notifies of browser's hash change
        https://github.com/dashed/react-hashchange*/}
        <HashChange
            onChange={this.onHashChanged}
        />
        
        <div className={classes.App}>
          <Header
            imgArrowLeft={this.state.header.imgArrowLeft}
            imgArrowRight={this.state.header.imgArrowRight}
            imgUrlLeft={this.state.header.imgUrlLeft}
            title={curSection.headerTitle}
            imgUrlRight={this.state.header.imgUrlRight}
            onRouteChanged={this.onRouteChanged}
            state={this.state}
            forceRefresh={this.state.header.forceRefresh} />
          
          <Pilot
            title={curSection.pilot.title}
            bodyText={curSection.pilot.bodyText}
            fadeToggle={this.state.fadeToggle} />
          
          <Section
            curSection={curSection}
            fadeToggle={this.state.fadeToggle} />
        </div>
      
      </HashRouter>
    )
  }
}

export default App;
