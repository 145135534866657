
/// add livepath prefix if we are live,
// else use relative paths of routes defined in each section
export function getValidRoute (route)
{
    // are we testing online?
    if (window.location.hostname !== "localhost") {
        // 
        
    }
    return "";
}

export function getValidSectionIndexByRoute(sections, route)
{
    //console.log(props.sections);
    let itemIndex = sections.findIndex(item => {
        //console.log(item.route);
        return (item.route === route);
    });

    // -1 for ex. if user typed 'mysite.com/' into address bar, 
    // but the site.json does not define that route; in that case, 
    // we should assume 'first in list' section/route is selected
    //console.log(itemIndex); 
    if (itemIndex === -1) {
        itemIndex = 0;
    }

    return itemIndex;
}

export function getValidSectionByRoute(sections, route)
{
    //console.log(props.sections);
    let itemIndex = sections.findIndex(item => {
        //console.log(item.route);
        return (item.route === route);
    });

    // -1 for ex. if user typed 'mysite.com/' into address bar, 
    // but the site.json does not define that route; in that case, 
    // we should assume 'first in list' section/route is selected
    //console.log(itemIndex); 
    if (itemIndex === -1) {
        itemIndex = 0;
    }

    return sections[itemIndex];
}